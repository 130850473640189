import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Nullable } from '@babylonjs/core'
import { AiOutlineClose, AiOutlineArrowDown } from 'react-icons/ai'
import { LuckyWheel } from '@lucky-canvas/react'

let containerRef: React.MutableRefObject<Nullable<HTMLDivElement>>

export const LuckywheelWindow = () => {
    containerRef = useRef<Nullable<HTMLDivElement>>(null)
    const [blocks] = useState([{ padding: '10px', background: '#869cfa' }])
    const [prizes] = useState([
        { background: '#e9e8fe', fonts: [{ text: 'BTC' }] },
        { background: '#b8c5f2', fonts: [{ text: 'TGT' }] },
        { background: '#e9e8fe', fonts: [{ text: 'USDT' }] },
        { background: '#b8c5f2', fonts: [{ text: 'Pizza' }] },
        { background: '#e9e8fe', fonts: [{ text: 'Tea' }] },
        { background: '#b8c5f2', fonts: [{ text: 'Toy' }] },
    ])
    const [buttons] = useState([
        { radius: '40%', background: '#617df2' },
        { radius: '35%', background: '#afc8ff' },
        {
            radius: '30%',
            background: '#869cfa',
            pointer: true,
            fonts: [{ text: 'Start', top: '-10px' }],
        },
    ])
    const myLucky = useRef<any>()

    return (
        <LuckyWindowContainer ref={containerRef}>
            <HeadDiv>
                <HeadLabel>Lucky Wheel</HeadLabel>
                <AiOutlineClose
                    style={{
                        display: 'inline-block',
                        color: 'white',
                        position: 'relative',
                        top: '15px',
                        float: 'right',
                        width: '20px',
                        height: '20px',
                        right: '16px',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        containerRef.current!.style.display = 'none'
                    }}
                ></AiOutlineClose>
            </HeadDiv>
            <GameboardGrid>
                <LuckyWheel
                    ref={myLucky}
                    width="300px"
                    height="300px"
                    blocks={blocks}
                    prizes={prizes}
                    buttons={buttons}
                    onStart={() => {
                        // 点击抽奖按钮会触发star回调

                        myLucky.current.play()
                        setTimeout(() => {
                            const index = (Math.random() * 6) >> 0
                            myLucky.current.stop(index)
                        }, 2500)
                    }}
                    onEnd={(prize) => {
                        // 抽奖结束会触发end回调
                        alert(
                            'Congratulations you get ' +
                                prize.fonts[0].text +
                                ' reward!'
                        )
                    }}
                />
            </GameboardGrid>
        </LuckyWindowContainer>
    )
}

const LuckyWindowContainer = styled.div`
    display: none;
    position: fixed;
    aligh-self: center;
    overflow-y: auto;
    width: 30%;
    height: 50%;
    min-width: 500px;
    min-height: 500px;

    background-color: rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 10px;
`

const HeadDiv = styled.div`
    display: block;
    position: sticky;
    z-index: 4;
    top: 0px;
    height: 40px;
    background-color: rgba(0, 0, 0, 1);
`

const HeadLabel = styled.label`
    display: inline-block;
    position: relative;
    color: white;
    font-size: 24px;
    font-family: Roboto;
    font-weight: 500;
    top: 10px;
    left: 20px;
`

const GameboardGrid = styled.div`
    display: flex;
    position: relative;
    width: 100%;

    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
`

LuckywheelWindow.showWindow = (show: boolean) => {
    if (show) {
        containerRef.current!.style.display = 'block'
    } else {
        containerRef.current!.style.display = 'none'
    }

    /*
    if(containerRef.current?.style.display===""||containerRef.current?.style.display==="none"){
        containerRef.current.style.display='block';
    }else{
        if(containerRef.current?.style.display==='block'){
            containerRef.current.style.display='none';
        }
    }
    */
}
