import { Buffer } from 'buffer'
import React, { useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import type { Adapter } from '@solana/wallet-adapter-base'
import { ed25519 } from '@noble/curves/ed25519'
import { Nullable } from '@babylonjs/core'
import bs58 from 'bs58'
// import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { WalletMultiButton } from '../customWalletButton/CustomWalletMultiButton'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { GameWindow } from '../gameWindow'
import { NFTMarketWindow } from '../NFTMarketWindow'
import { DashboardWindow } from '../dashboardWindow'
import { verifySignIn } from '@solana/wallet-standard-util'
import { type SolanaSignInInput } from '@solana/wallet-standard-features'
// import { Header, Payload, SIWS } from '@web3auth/sign-in-with-solana'
import { API_GATEWAY } from '../../../lib/utils'
import toast, { Toaster } from 'react-hot-toast'

import * as buffer from 'buffer'
window.Buffer = buffer.Buffer

let containerRef: React.MutableRefObject<Nullable<HTMLDivElement>>

export const HeaderWindow = () => {
    containerRef = useRef<Nullable<HTMLDivElement>>(null)
    const notify = () => toast('Coming soon...')

    const { publicKey, connected, signMessage, disconnect } = useWallet()
    const connection = useConnection()

    const domain = window.location.host
    const origin = window.location.origin
    let nonce = ''
    // let message: SIWS
    // Generate a message for signing
    // The nonce is generated on the server side
    // function createSolanaMessage(address, statement) {
    //     const header = new Header()
    //     header.t = 'sip99'
    //     const payload = new Payload()
    //     payload.domain = domain
    //     payload.address = address
    //     payload.uri = origin
    //     payload.statement = statement
    //     payload.version = '1'
    //     payload.chainId = 1
    //     message = new SIWS({
    //         header,
    //         payload,
    //     })
    //     // we need the nonce for verification so getting it in a global variable
    //     // Returning the prepared message
    //     return message.prepareMessage()
    // }

    // const signInWithSolana = useCallback(async () => {
    //     try {
    //         if (!publicKey) throw new Error('Wallet not connected!')
    //         if (!signMessage)
    //             throw new Error('Wallet does not support message signing!')
    //         console.log('start create message')
    //         const solanamessage = createSolanaMessage(
    //             publicKey.toString(),
    //             'Sign in with Solana to the app.'
    //         )
    //         console.log('end create message')

    //         const encodedMessage = new TextEncoder().encode(solanamessage)
    //         console.log('start signing...')
    //         const signedMessage = await signMessage(encodedMessage)
    //         console.log('signature: ' + bs58.encode(signedMessage))
    //         const signature = { t: 'sip99', s: bs58.encode(signedMessage) }
    //         const payload = message.payload
    //         payload.address = publicKey.toString()
    //         // const resp = await message.verify({ payload, signature })
    //         const response = await fetch(API_GATEWAY + '/api/auth/signin', {
    //             method: 'POST',
    //             mode: 'cors',
    //             body: JSON.stringify({
    //                 header: message.header,
    //                 payload: payload,
    //                 signature: signature,
    //             }),
    //         })
    //         const json = await response.json()
    //         console.log('result: ' + JSON.stringify(json))
    //         if (json.ok) {
    //             console.log('sigin success')
    //         } else {
    //             console.log('not success')
    //         }
    //     } catch (error) {
    //         console.log(('signin error: ' + error) as string)
    //     }
    // }, [publicKey, signMessage])
    const walletSignIn = useCallback(async () => {
        try {
            if (!publicKey) throw new Error('Wallet not connected!')
            if (!signMessage)
                throw new Error('Wallet does not support message signing!')

            const message = new TextEncoder().encode(
                `${
                    window.location.host
                } wants you to sign in with your Solana account:\n${publicKey.toBase58()}\n\nPlease sign in Tyche Games.`
            )
            const signature = await signMessage(message)
            const toHexString = (buffer: Buffer) =>
                buffer.reduce(
                    (str, byte) => str + byte.toString(16).padStart(2, '0'),
                    ''
                )
            const response = await fetch(API_GATEWAY + '/api/auth/signin', {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify({
                    signature: toHexString(Buffer.from(signature)),
                    message: `${
                        window.location.host
                    } wants you to sign in with your Solana account:\n${publicKey.toBase58()}\n\nPlease sign in Tyche Games.`,
                    publickey: publicKey.toBytes().toString(),
                    address: publicKey.toString(),
                }),
            })

            const json = await response.json()
            console.log('result: ' + JSON.stringify(json))
            if (json.ok) {
                console.log('sigin success')
                const presale_info = await fetch(
                    API_GATEWAY + '/api/presale/get_presale_info_api',
                    {
                        method: 'post',
                        mode: 'cors',
                        credentials: 'include',
                    }
                )
                const json = await presale_info.json()
                console.log('presale: ' + JSON.stringify(json))
            } else {
                console.log('not success')
            }

            if (!ed25519.verify(signature, message, publicKey.toBytes()))
                throw new Error('Message signature invalid!')
            // console.log('signature: ' + signature)
            // console.log('message: ' + message)
            // console.log('publicKey: ' + publicKey.toBytes())

            // console.log(`Message signature: ${bs58.encode(signature)}`)
        } catch (error: any) {
            console.log('error', `Sign Message failed: ${error?.message}`)
            await disconnect()
        }
    }, [publicKey, signMessage])

    useEffect(() => {
        if (connected) {
            ;(async () => {
                walletSignIn()
                // signInWithSolana()
            })()
            console.log('connected: ' + publicKey)
        } else {
            console.log('disconnected')
        }
    }, [connected])

    return (
        <Container id="headerWindow" ref={containerRef}>
            <div
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    paddingLeft: '20px',
                }}
            >
                <img
                    src="/UI/images/logo@2x.png"
                    width={170}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        window.open('https://www.tychegames.club', '_blank')
                    }}
                ></img>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexGrow: 7,
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    onClick={() => {
                        GameWindow.showWindow(true)
                        NFTMarketWindow.showWindow(false)
                        DashboardWindow.showWindow(false)
                    }}
                >
                    Games
                </Button>
                <Button
                    onClick={() => {
                        GameWindow.showWindow(false)
                        NFTMarketWindow.showWindow(false)
                        DashboardWindow.showWindow(true)
                    }}
                >
                    Dashboard
                </Button>
                <Button
                    onClick={() => {
                        GameWindow.showWindow(false)
                        NFTMarketWindow.showWindow(true)
                        DashboardWindow.showWindow(false)
                    }}
                >
                    Market
                </Button>
                <Button onClick={notify}>Presale</Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexGrow: 2,
                    justifyContent: 'flex-end',
                    paddingRight: '20px',
                }}
            >
                <WalletMultiButton></WalletMultiButton>
            </div>
            <Toaster
                position="top-center"
                toastOptions={{
                    duration: 2000,
                    style: { backgroundColor: 'black', color: '#f1c00e' },
                }}
            />
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    position: fixed;
    direction: row;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 4;
    background-color: rgba(58, 58, 58, 1);
    justify-content: center;
    align-items: center;
`
const Button = styled.button`
    background-color: rgba(58, 58, 58, 1);
    color: #f1c00e;
    width: 120px;
    height: 40px;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue';
    border: 0;
    font-size: 18px;
    cursor: pointer;
    border-radius: 15px;
    &:hover {
        background-color: black;
    }
`

HeaderWindow.showWindow = (show: boolean) => {
    if (show) {
        containerRef.current!.style.display = 'block'
    } else {
        containerRef.current!.style.display = 'none'
    }
    /*
    if(containerRef.current?.style.display===""||containerRef.current?.style.display==="none"){
        containerRef.current.style.display='block';
    }else{
        if(containerRef.current?.style.display==='block'){
            containerRef.current.style.display='none';
        }
    }
    */
}
