// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "meshUboDeclaration";
const shader = `uniform mat4 world;uniform float visibility;#define WORLD_UBO
`;
// Sideeffect
ShaderStore.IncludesShadersStore[name] = shader;
/** @hidden */
export const meshUboDeclaration = { name, shader };
