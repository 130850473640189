// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "clipPlaneFragment";
const shader = `#if defined(CLIPPLANE) || defined(CLIPPLANE2) || defined(CLIPPLANE3) || defined(CLIPPLANE4) || defined(CLIPPLANE5) || defined(CLIPPLANE6)
if (false) {}#endif
#ifdef CLIPPLANE
elseif (fClipDistance>0.0){discard;}#endif
#ifdef CLIPPLANE2
elseif (fClipDistance2>0.0){discard;}#endif
#ifdef CLIPPLANE3
elseif (fClipDistance3>0.0){discard;}#endif
#ifdef CLIPPLANE4
elseif (fClipDistance4>0.0){discard;}#endif
#ifdef CLIPPLANE5
elseif (fClipDistance5>0.0){discard;}#endif
#ifdef CLIPPLANE6
elseif (fClipDistance6>0.0){discard;}#endif
`;
// Sideeffect
ShaderStore.IncludesShadersStoreWGSL[name] = shader;
/** @hidden */
export const clipPlaneFragment = { name, shader };
