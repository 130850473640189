import { BaseWalletMultiButton } from '@solana/wallet-adapter-react-ui'

import type { ButtonProps } from '@solana/wallet-adapter-react-ui/src/Button'

const LABELS = {
    'change-wallet': 'Change wallet',
    connecting: 'Connecting ...',
    'copy-address': 'Copy address',
    copied: 'Copied',
    disconnect: 'Disconnect',
    'has-wallet': 'Connect',
    'no-wallet': 'Connect ',
} as const

export function WalletMultiButton(props: ButtonProps) {
    return <BaseWalletMultiButton {...props} labels={LABELS} />
}
