import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Nullable } from '@babylonjs/core'
import { AiOutlineClose, AiOutlineArrowDown } from 'react-icons/ai'
import { useWallet } from '@solana/wallet-adapter-react'

let containerRef: React.MutableRefObject<Nullable<HTMLDivElement>>

export const RankingWindow = () => {
    containerRef = useRef<Nullable<HTMLDivElement>>(null)
    const { publicKey } = useWallet()

    const UserRecordList = () => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                top: '10px',
                width: '100%',
                height: '30px',
                justifyContent: 'space-between',
                color: 'white',
            }}
        >
            <span
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    width: '100%',

                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {publicKey
                    ? publicKey.toString().substring(0, 6) +
                      '...' +
                      publicKey
                          .toString()
                          .substring(publicKey.toString().length - 5)
                    : ''}
            </span>
            <span
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                100000
            </span>
            <div
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    width: '100%',

                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                1
            </div>
        </div>
    )

    return (
        <RandingContainer ref={containerRef}>
            <HeadDiv>
                <HeadLabel>Ranking List</HeadLabel>
                <AiOutlineClose
                    style={{
                        display: 'inline-block',
                        color: 'white',
                        position: 'relative',
                        top: '15px',
                        float: 'right',
                        width: '20px',
                        height: '20px',
                        right: '16px',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        containerRef.current!.style.display = 'none'
                    }}
                ></AiOutlineClose>
            </HeadDiv>
            <RankingListGrid>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '10px',
                        width: '100%',
                        height: '30px',
                        background: 'rgba(58, 58, 58, 1)',
                        color: 'white',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexGrow: 1,
                            width: '100%',

                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        User
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexGrow: 1,
                            width: '100%',

                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        Points
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexGrow: 1,
                            width: '100%',

                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        Rank
                    </div>
                </div>
                {publicKey ? <UserRecordList /> : ''}
            </RankingListGrid>
        </RandingContainer>
    )
}

const RandingContainer = styled.div`
    display: none;
    position: fixed;
    aligh-self: center;
    overflow-y: auto;
    width: 30%;
    min-width: 500px;
    min-height: 600px;

    background-color: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 10px;
`

const HeadDiv = styled.div`
    display: block;
    position: sticky;
    z-index: 4;
    top: 0px;
    height: 40px;
    background-color: rgba(0, 0, 0, 1);
`

const HeadLabel = styled.label`
    display: inline-block;
    position: relative;
    color: #f1c00e;
    font-size: 24px;
    font-family: Roboto;
    font-weight: 500;
    top: 10px;
    left: 20px;
`

const RankingListGrid = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px;
    background-color: rgba(0, 0, 0, 1);
`

RankingWindow.showWindow = (show: boolean) => {
    if (show) {
        containerRef.current!.style.display = 'block'
    } else {
        containerRef.current!.style.display = 'none'
    }

    /*
    if(containerRef.current?.style.display===""||containerRef.current?.style.display==="none"){
        containerRef.current.style.display='block';
    }else{
        if(containerRef.current?.style.display==='block'){
            containerRef.current.style.display='none';
        }
    }
    */
}
