import './styles.css'
import React, { useContext, useMemo, useRef, Suspense } from 'react'
import '@babylonjs/loaders'
import {
    AnimationGroup,
    float,
    Mesh,
    MeshAssetTask,
    Nullable,
    StandardMaterial,
    VideoTexture,
} from '@babylonjs/core'
import { Texture } from '@babylonjs/core/Materials/Textures/texture.js'
import {
    Engine,
    Scene,
    useAssetManager,
    TaskType,
    AssetManagerContext,
    AssetManagerContextProvider,
    useBeforeRender,
    Task,
    useScene,
    SceneEventArgs,
    useCanvas,
} from 'react-babylonjs'
import { Vector3 } from '@babylonjs/core/Maths/math'

import './App.css'
import * as BABYLON from '@babylonjs/core'
import { AdvancedDynamicTexture, Rectangle, TextBlock } from '@babylonjs/gui'
import { Dropdown } from './component/Dropdown'
import { SwapWindow } from './component/UI/swapWindow'
import { DashboardWindow } from './component/UI/dashboardWindow'
import { GameWindow } from './component/UI/gameWindow'
import { NFTMarketWindow } from './component/UI/NFTMarketWindow'
import { HeaderWindow } from './component/UI/headerWindow'
import { LuckywheelWindow } from './component/UI/luckywheelWindow'
import { RankingWindow } from './component/UI/rankingWindow'

type EqSkyboxProps = {
    rootUrl: string
    blur: float
    name: string
}
const SkyboxUrl = '/assets/sky/FS003_Day.png'
const baseUrl = '/assets/models/'

const modelAssetTasks: Task[] = [
    {
        taskType: TaskType.Mesh,
        name: 'cartoonCity',
        rootUrl: `${baseUrl}`,
        sceneFilename: 'city.gltf',
    },
    {
        taskType: TaskType.Mesh,
        name: 'avart1',
        rootUrl: `${baseUrl}avatar/`,
        sceneFilename: 'avart1-hippop-idle-walking.glb',
    },
    {
        taskType: TaskType.Mesh,
        name: 'avart2',
        rootUrl: `${baseUrl}avatar/`,
        sceneFilename: 'avart2-idle-breakdance.glb',
    },
    {
        taskType: TaskType.Mesh,
        name: 'petDog3',
        rootUrl: `${baseUrl}dog/`,
        sceneFilename: 'dog3-thinking-hippop.glb',
    },
    // { taskType: TaskType.Mesh,  name: 'cartoonGuy',rootUrl: `${baseUrl}avatar/`, sceneFilename: 'bear-dancing-cheer.glb' },
    // { taskType: TaskType.Mesh,  name: 'cartoonDude',rootUrl: `${baseUrl}dude/`, sceneFilename: 'dude.babylon' },
    {
        taskType: TaskType.Mesh,
        name: 'cartoonBalloon',
        rootUrl: `${baseUrl}balloon/`,
        sceneFilename: 'balloon.gltf',
    },
]
const onButtonClicked = () => {
    console.log('Button clicked')
}
// lucky wheel button clicked
const onLuckyWheelButtonClicked = () => {
    console.log('lucky wheel clicked')
    GameWindow.showWindow(false)
    NFTMarketWindow.showWindow(false)
    DashboardWindow.showWindow(false)
    RankingWindow.showWindow(false)
    LuckywheelWindow.showWindow(true)
}

//randing list button clicked
const onRankingButtonClicked = () => {
    console.log('lucky wheel clicked')
    GameWindow.showWindow(false)
    NFTMarketWindow.showWindow(false)
    DashboardWindow.showWindow(false)
    LuckywheelWindow.showWindow(false)
    RankingWindow.showWindow(true)
}
//load model fallback
const MyFallback = () => {
    let boxRef = useRef<Nullable<Mesh>>()
    const context = useContext(AssetManagerContext)
    console.log('context in fallback', context)

    useBeforeRender((scene) => {
        if (boxRef.current) {
            var deltaTimeInMillis = scene.getEngine().getDeltaTime()

            const rpm = 10
            boxRef.current.rotation.x = Math.PI / 4
            boxRef.current.rotation.y +=
                (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000)
        }
    })

    const eventData = context?.lastProgress?.eventData
    return (
        <>
            <adtFullscreenUi name="ui">
                <rectangle
                    name="rect"
                    height="60px"
                    width="500px"
                    thickness={0}
                >
                    <rectangle thickness={0}>
                        {eventData !== undefined && (
                            <textBlock
                                text={`${
                                    eventData.totalCount -
                                    eventData.remainingCount
                                }/${eventData.totalCount}`}
                                fontStyle="bold"
                                fontSize={50}
                                color="white"
                            />
                        )}

                        {eventData === undefined && (
                            <textBlock
                                text="Tyche Games loading"
                                fontStyle="bold"
                                fontSize={50}
                                color="white"
                            />
                        )}
                    </rectangle>
                </rectangle>
            </adtFullscreenUi>
            <box ref={boxRef} name="fallback" position-x={500} size={70} />
        </>
    )
}

// guys walk
class walk {
    public turn = 0
    public dist = 0
    constructor(turn: number, dist: number) {
        this.dist = dist
        this.turn = turn
    }
}

//load scene1 3D models
const MyModels = () => {
    const assetManagerResult = useAssetManager(modelAssetTasks)

    const scene = useScene()

    useMemo(() => {
        console.log('Loaded Tasks', assetManagerResult)

        //load city
        const cartoonCityTask = assetManagerResult.taskNameMap[
            'cartoonCity'
        ] as MeshAssetTask
        cartoonCityTask.loadedMeshes[0].position = new Vector3(0, 0, 0)
        cartoonCityTask.loadedMeshes[0].scaling = new Vector3(1, 1, 1)
        //cartoonCityTask.loadedMeshes[0].receiveShadows=true;
        for (var i = 0; i < cartoonCityTask.loadedMeshes.length; i++) {
            if (cartoonCityTask.loadedMeshes[i].name === 'ROAD') {
                console.log(cartoonCityTask.loadedMeshes[i].name)
                cartoonCityTask.loadedMeshes[i].receiveShadows = true
            }
            if (cartoonCityTask.loadedMeshes[i].name === 'ROAD.Lines.12') {
                console.log(cartoonCityTask.loadedMeshes[i].name)
                cartoonCityTask.loadedMeshes[i].receiveShadows = true
            }
        }

        const avart1Task = assetManagerResult.taskNameMap[
            'avart1'
        ] as MeshAssetTask
        let avart1Mesh = avart1Task.loadedMeshes[0]

        let avart1AnimationGroup = avart1Task.loadedAnimationGroups
        console.log(avart1AnimationGroup)
        avart1AnimationGroup[0].stop()
        avart1AnimationGroup[2].start()
        avart1AnimationGroup[2].loopAnimation = true
        avart1Task.loadedMeshes[0].position = new Vector3(500, -120, -450)
        avart1Task.loadedMeshes[0].rotate(
            BABYLON.Axis.Y,
            BABYLON.Tools.ToRadians(0),
            BABYLON.Space.LOCAL
        )
        avart1Task.loadedMeshes[0].scaling = new Vector3(50, 50, 50)
        avart1Task.loadedMeshes[0].isPickable = true
        DashboardWindow.addAvart(avart1Mesh)
        ;(
            scene
                ?.getLightByName('direct-light')
                ?.getShadowGenerator() as BABYLON.ShadowGenerator
        ).addShadowCaster(avart1Mesh)

        //load avart #2

        const avart2Task = assetManagerResult.taskNameMap[
            'avart2'
        ] as MeshAssetTask
        let avart2Mesh = avart2Task.loadedMeshes[0]

        let avart2AnimationGroup = avart2Task.loadedAnimationGroups
        console.log(avart2AnimationGroup)
        avart2AnimationGroup[0].stop()
        avart2AnimationGroup[1].start()
        avart2AnimationGroup[1].loopAnimation = true
        avart2Task.loadedMeshes[0].position = new Vector3(500, -120, -450)
        avart2Task.loadedMeshes[0].rotate(
            BABYLON.Axis.Y,
            BABYLON.Tools.ToRadians(0),
            BABYLON.Space.LOCAL
        )
        avart2Task.loadedMeshes[0].scaling = new Vector3(50, 50, 50)
        avart2Task.loadedMeshes[0].isPickable = true
        DashboardWindow.addAvart(avart2Mesh)
        avart2Task.loadedMeshes[0].setEnabled(false)
        ;(
            scene
                ?.getLightByName('direct-light')
                ?.getShadowGenerator() as BABYLON.ShadowGenerator
        ).addShadowCaster(avart2Mesh)

        // load dog pet 2
        const dogPetTask = assetManagerResult.taskNameMap[
            'petDog3'
        ] as MeshAssetTask
        let dogPetMesh = dogPetTask.loadedMeshes[0]
        let dogPetAnimationGroup: AnimationGroup[] =
            dogPetTask.loadedAnimationGroups
        dogPetMesh.isPickable = true
        dogPetAnimationGroup[0].loopAnimation = true
        dogPetMesh.position = new Vector3(450, -120, -450)
        dogPetMesh.scaling = new Vector3(50, 50, 50)
        dogPetMesh.rotation = new Vector3(0, 10, 0)
        ;(
            scene
                ?.getLightByName('direct-light')
                ?.getShadowGenerator() as BABYLON.ShadowGenerator
        ).addShadowCaster(dogPetMesh)

        scene?.onPointerObservable.add((evt: BABYLON.PointerInfo) => {
            if (evt.type === BABYLON.PointerEventTypes.POINTERPICK) {
                var ray = scene.createPickingRay(
                    scene.pointerX,
                    scene.pointerY,
                    BABYLON.Matrix.Identity(),
                    scene.activeCamera
                )
                var hit = scene.pickWithRay(ray)
                console.log(hit?.pickedMesh?.parent?.name)
                console.log(hit?.pickedMesh?.name)

                if (hit?.pickedMesh?.parent?.name === 'avart1') {
                    console.log(avart1AnimationGroup[2].isPlaying)
                    console.log(avart1AnimationGroup[1].isPlaying)
                    if (avart1AnimationGroup[2].isPlaying === true) {
                        avart1AnimationGroup[2].stop()
                        avart1AnimationGroup[1].start()
                        avart1AnimationGroup[1].loopAnimation = true
                        console.log('idle')
                    } else {
                        if (avart1AnimationGroup[1].isPlaying === true) {
                            avart1AnimationGroup[1].stop()
                            avart1AnimationGroup[2].start()
                            avart1AnimationGroup[2].loopAnimation = true
                        } else {
                            avart1AnimationGroup[2].stop()
                            avart1AnimationGroup[1].start()
                            avart1AnimationGroup[1].loopAnimation = true
                        }
                    }

                    console.log('Boy hited')
                }
                if (hit?.pickedMesh?.parent?.name === 'avart2') {
                    console.log(avart2AnimationGroup[1].isPlaying)
                    console.log(avart2AnimationGroup[0].isPlaying)
                    if (avart2AnimationGroup[1].isPlaying === true) {
                        avart2AnimationGroup[1].stop()
                        avart2AnimationGroup[0].start()
                        avart2AnimationGroup[0].loopAnimation = true
                        console.log('idle')
                    } else {
                        if (avart2AnimationGroup[0].isPlaying === true) {
                            avart2AnimationGroup[0].stop()
                            avart2AnimationGroup[1].start()
                            avart2AnimationGroup[1].loopAnimation = true
                        } else {
                            avart2AnimationGroup[1].stop()
                            avart2AnimationGroup[0].start()
                            avart2AnimationGroup[0].loopAnimation = true
                        }
                    }

                    console.log('Boy2 hited')
                }
            }
        })

        const cartoonBalloonTask = assetManagerResult.taskNameMap[
            'cartoonBalloon'
        ] as MeshAssetTask
        cartoonBalloonTask.loadedMeshes[0].position = new Vector3(300, 350, 200)
        cartoonBalloonTask.loadedMeshes[0].scaling = new Vector3(300, 300, 300)
    }, [])
    return null
}
const LuckwheelLOGO = () => {
    let planeRef = useRef<Nullable<Mesh>>()

    // planeRef.current!.isVisible=true;

    return (
        <plane
            name="dialog2"
            ref={planeRef}
            rotation={new Vector3(0, 0, 0)}
            size={400}
            position={new Vector3(1010, 120, -1000)}
            sideOrientation={Mesh.BACKSIDE}
            isVisible={true}
        >
            <advancedDynamicTexture
                name="dialogTexture"
                height={300}
                width={720}
                createForParentMesh={true}
                hasAlpha={true}
                generateMipMaps={true}
                samplingMode={Texture.TRILINEAR_SAMPLINGMODE}
            >
                <rectangle
                    name="rect-1"
                    height={0.3}
                    width={0.7}
                    thickness={12}
                    cornerRadius={12}
                >
                    <rectangle>
                        <babylon-button
                            name="close-icon"
                            background="green"
                            hoverCursor="pointer"
                            onPointerDownObservable={onLuckyWheelButtonClicked}
                        >
                            <textBlock
                                text={'LUCKY WHEEL'}
                                fontFamily="FontAwesome"
                                fontStyle="bold"
                                fontSize={50}
                                color="white"
                            />
                        </babylon-button>
                    </rectangle>
                </rectangle>
            </advancedDynamicTexture>
        </plane>
    )
}
const Ranking = () => {
    let planeRef = useRef<Nullable<Mesh>>()

    // planeRef.current!.isVisible=true;

    return (
        <plane
            name="dialog3"
            ref={planeRef}
            rotation={new Vector3(0, 0.5, 0)}
            size={400}
            position={new Vector3(900, 400, -100)}
            sideOrientation={Mesh.BACKSIDE}
            isVisible={true}
        >
            <advancedDynamicTexture
                name="dialogTexture"
                height={300}
                width={720}
                createForParentMesh={true}
                hasAlpha={true}
                generateMipMaps={true}
                samplingMode={Texture.TRILINEAR_SAMPLINGMODE}
            >
                <rectangle
                    name="rect-1"
                    height={0.3}
                    width={0.7}
                    thickness={12}
                    cornerRadius={12}
                >
                    <rectangle>
                        <babylon-button
                            name="close-icon"
                            background="green"
                            hoverCursor="pointer"
                            onPointerDownObservable={onRankingButtonClicked}
                        >
                            <textBlock
                                text={'RANKING'}
                                fontFamily="FontAwesome"
                                fontStyle="bold"
                                fontSize={50}
                                color="white"
                            />
                        </babylon-button>
                    </rectangle>
                </rectangle>
            </advancedDynamicTexture>
        </plane>
    )
}

const CASINOlogo = () => {
    let casinoRef = useRef<Nullable<Mesh>>()
    // casinoRef.current!.isVisible=true;
    return (
        <plane
            name="dialog"
            ref={casinoRef}
            size={400}
            position={new Vector3(0, 400, -850)}
            sideOrientation={Mesh.BACKSIDE}
            isVisible={true}
        >
            <advancedDynamicTexture
                name="dialogTexture"
                height={300}
                width={700}
                createForParentMesh={true}
                hasAlpha={true}
                generateMipMaps={true}
                samplingMode={Texture.TRILINEAR_SAMPLINGMODE}
            >
                <rectangle
                    name="rect-1"
                    height={0.3}
                    width={0.6}
                    thickness={12}
                    cornerRadius={12}
                >
                    <rectangle>
                        <babylon-button
                            name="close-icon"
                            background="yellow"
                            hoverCursor="pointer"
                            onPointerDownObservable={onButtonClicked}
                        >
                            <textBlock
                                text={'PLAY GAMES'}
                                fontFamily="FontAwesome"
                                fontStyle="bold"
                                fontSize={50}
                                color="red"
                            />
                        </babylon-button>
                    </rectangle>
                </rectangle>
            </advancedDynamicTexture>
        </plane>
    )
}
//skybox
const EqSkybox = (props: EqSkyboxProps) => {
    return (
        <box
            name="EqiSkybox"
            size={5000}
            infiniteDistance={true}
            isPickable={true}
        >
            <pbrMaterial
                name={props.name + 'skybox-material'}
                backFaceCulling={false}
                disableLighting={false}
                microSurface={1.0 - props.blur}
            >
                <equiRectangularCubeTexture
                    size={512}
                    url={props.rootUrl}
                    coordinatesMode={Texture.SKYBOX_MODE}
                    assignTo={'reflectionTexture'}
                />
            </pbrMaterial>
        </box>
    )
}

const UI = () => {
    //Load UI

    const createUI = async (
        instance: AdvancedDynamicTexture,
        scene: BABYLON.Scene
    ) => {
        let loadUI = await instance.parseFromURLAsync('/UI/guiTexture1920.json')

        //load dashboard board
        let dashboardRec = instance!.getControlByName('DashboardRec')
        //load swap board
        let swapRec = instance.getControlByName('SwapRec') as Rectangle
        //load game board
        let gameRec = instance.getControlByName('GameboardRec')

        //get UI controlls
        let dashboardBtn = instance.getControlByName('DashboardBtn')
        let connectBtn = instance.getControlByName('ConnectBtn')
        let gameBtn = instance.getControlByName('GameBtn')
        let swapBtn = instance.getControlByName('SwapBtn')
        let NFTBtn = instance.getControlByName('NFTBtn')

        //add dropdown control to swapboard
        var dropdown = new Dropdown(
            'TUST',
            swapRec,
            '310px',
            '50px',
            'white',
            '#00000000'
        )
        dropdown.top = '200px'
        dropdown.left = '-160px'
        dropdown.addOption(0, 'TUST')
        dropdown.addOption(1, 'UST')
        dropdown.addOption(2, 'TGT')
        //swapGrid.addControl(dropdown,1,0);
        var dropdownB = new Dropdown(
            'UST',
            swapRec,
            '310px',
            '50px',
            'white',
            '#00000000'
        )
        dropdownB.top = '200px'
        dropdownB.left = '160px'
        dropdownB.addOption(0, 'TUST')
        dropdownB.addOption(1, 'UST')
        dropdownB.addOption(2, 'TGT')

        console.log(dropdown)

        let totalburnedValue = instance.getControlByName(
            'TotalBurnedValue'
        ) as TextBlock
        totalburnedValue.text = '🔥 10,000 TGT'
        console.log('\u{1F525}')
        //setText(instance.getControlByName('ConnectText') as TextBlock) ;
        // connectText=instance.getControlByName('ConnectText') as TextBlock;

        //get all ui close button
        let dashcloseBtn = instance.getControlByName('DashCloseBtn')
        let swapcloseBtn = instance.getControlByName('SwapCloseBtn')
        let gamecloseBtn = instance.getControlByName('GameCloseBtn')

        //invisible the ui
        dashboardRec!.isVisible = false
        swapRec!.isVisible = false
        gameRec!.isVisible = false

        //set over cursor
        dashboardBtn!.hoverCursor = 'pointer'
        dashcloseBtn!.hoverCursor = 'pointer'
        connectBtn!.hoverCursor = 'pointer'
        swapBtn!.hoverCursor = 'pointer'
        swapcloseBtn!.hoverCursor = 'pointer'
        gameBtn!.hoverCursor = 'pointer'
        NFTBtn!.hoverCursor = 'pointer'
        gamecloseBtn!.hoverCursor = 'pointer'

        //add UI button click event
        NFTBtn?.onPointerClickObservable.add(() => {
            NFTMarketWindow.showWindow(true)
            GameWindow.showWindow(false)
            SwapWindow.showWindow(false)
            DashboardWindow.showWindow(false)
        })
        gameBtn?.onPointerClickObservable.add(() => {
            //gameRec!.isVisible=!gameRec!.isVisible;

            //var buttonbox=document.getElementById('buttonbox');
            //var actorWin=document.getElementById('actorWin');
            // actorWin!.style.display="grid";

            //console.log(actorWin);
            //buttonbox!.style.display="block";
            //showActorWin();
            //console.log(buttonb;

            //if(swapRec!.isVisible===true){
            //  swapRec!.isVisible=false;
            // }

            //if(dashboardRec!.isVisible===true){
            //  dashboardRec!.isVisible=false;
            // }
            GameWindow.showWindow(true)
            SwapWindow.showWindow(false)
            DashboardWindow.showWindow(false)
            NFTMarketWindow.showWindow(false)
        })
        swapBtn?.onPointerClickObservable.add(() => {
            //if(swapRec!.isVisible===false){
            //swapRec!.isVisible=true;
            //}
            SwapWindow.showWindow(true)
            DashboardWindow.showWindow(false)
            GameWindow.showWindow(false)
            NFTMarketWindow.showWindow(false)
            //if(document.getElementById('swapWindow1')!.style.display==='none'){
            //document.getElementById('swapWindow1')!.style.display='block';
            //} ;
            if (gameRec!.isVisible === true) {
                gameRec!.isVisible = false
            }

            if (dashboardRec!.isVisible === true) {
                dashboardRec!.isVisible = false
            }
        })
        connectBtn?.onPointerClickObservable.add(() => {})
        //dashboard clicked
        dashboardBtn?.onPointerClickObservable.add(() => {
            DashboardWindow.showWindow(true)
            SwapWindow.showWindow(false)
            GameWindow.showWindow(false)
            NFTMarketWindow.showWindow(false)
        })

        //add ui close button event
        dashcloseBtn?.onPointerClickObservable.add(() => {
            dashboardRec!.isVisible = false
        })

        swapcloseBtn?.onPointerClickObservable.add(() => {
            swapRec!.isVisible = false
        })

        gamecloseBtn?.onPointerClickObservable.add(() => {
            gameRec!.isVisible = false
        })
    }

    return (
        <adtFullscreenUi
            name="ADui"
            foreground={true}
            onCreated={createUI}
        ></adtFullscreenUi>
    )
}

const App = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {/** <ActorWindow></ActorWindow>*/}
            <NFTMarketWindow></NFTMarketWindow>
            <GameWindow></GameWindow>
            <SwapWindow></SwapWindow>
            <DashboardWindow></DashboardWindow>
            <LuckywheelWindow />
            <RankingWindow />
            <HeaderWindow></HeaderWindow>

            <Engine antialias adaptToDeviceRatio canvasId="Tyche-demo">
                <Scene>
                    <hemisphericLight
                        name="hemi-light"
                        intensity={0.6}
                        direction={Vector3.Up()}
                        range={500}
                        shadowEnabled={true}
                    ></hemisphericLight>

                    <directionalLight
                        name="direct-light"
                        intensity={2}
                        direction={new Vector3(2, -2, 1)}
                        range={1500}
                        shadowEnabled={true}
                    >
                        <shadowGenerator mapSize={1024}></shadowGenerator>
                    </directionalLight>

                    <EqSkybox
                        name="skybox"
                        blur={0.1}
                        rootUrl={SkyboxUrl}
                    ></EqSkybox>

                    <arcRotateCamera
                        id={'1'}
                        name="arcCamera"
                        target={new Vector3(500, 0, -500)}
                        radius={1500}
                        alpha={-Math.PI / 2}
                        beta={Math.PI / 2.4}
                        minZ={0.3}
                        upperBetaLimit={Math.PI / 2}
                        lowerRadiusLimit={500}
                        upperRadiusLimit={1500}
                        wheelPrecision={0.4}
                        onCreated={() => {}}
                    />

                    <AssetManagerContextProvider>
                        <Suspense fallback={<MyFallback />}>
                            <MyModels />
                        </Suspense>
                    </AssetManagerContextProvider>
                    <LuckwheelLOGO />
                    <CASINOlogo />
                    <Ranking />
                    <UI />
                </Scene>
            </Engine>
        </div>
    )
}

export default App
